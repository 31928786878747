import React, { Component } from 'react';
import { addApplicant, addParent, addSibling } from '../../API/applicant';
import { getSettingById } from '../../API/setting';
import {ParentList} from '../Form/ParentList';
import {SiblingList} from '../Form/SiblingList';
import '../../custom.css'
import CountrySelect from '../CountrySelect';
import {parseSSN} from '../../Helper';
import { withStyles } from '@material-ui/core/styles';
import {Slide, Dialog, Button, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

let year = [];

const startYear = new Date().getFullYear();
for(let i = startYear; i < startYear + 7; i++){
  year.push(i);
}

const styles = theme => ({
  textBox: {
    minHeight: '1.1875em'
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const siteLang = {
  'Svenska': {
    'pageTitle': 'Elevansökan British Junior',
    'headerStudent': 'Elev',
    'headerSibling': 'Syskon',
    'headerParent': 'Vårdnadshavare',
    'checkSibling': 'Syskon på British Junior',
    'addSibling': 'Lägg till ytterligare ett syskon',
    'addParent': 'Lägg till ytterligare en vårdnadshavare',
    'firstName': 'Förnamn',
    'lastName': 'Efternamn',
    'ssn': 'Personnummer',
    'ssnPlaceholder': 'ÅÅMMDD-XXXX',
    'sex': 'Kön',
    'boy': 'Pojke',
    'girl': 'Flicka',
    'startYear': 'Startår',
    'grade': 'Årskurs vid önskad skolstart',
    'language': 'Modersmål',
    'present': 'Nuvarande skola/förskola',
    'afternoonSchool': 'Önskar plats på Afternoon School (fritidshem)',
    'comment': 'Övriga kommentarer',
    'gdpr': 'Jag godkänner användandet av ovanstående personuppgifter i enlighet med',
    'submit': 'Skicka ansökan',
    'successMessage': 'Tack för din ansökan.',
    'successClose': 'Stäng',
    'failDuplicateMessage': 'Ansökan med detta personnr finns redan i systemet.',
    'failErrorMessage': 'Något gick fel, kontakta administratör.',
    'failClose': 'Tillbaka',
    'yes': 'Ja',
    'no': 'Nej',
    'address': 'Adress',
    'phoneNumber': 'Mobilnummer',
    'email': 'Epost'
  },
  'English': {
    'pageTitle': 'Student application British Junior',
    'headerStudent': 'Student',
    'headerSibling': 'Sibling',
    'headerParent': 'Legal custodian',
    'checkSibling': 'Siblings at British Junior',
    'addSibling': 'Add additional sibling',
    'addParent': 'Add additional legal custodian',
    'firstName': 'First name',
    'lastName': 'Last name',
    'ssn': 'Social security number',
    'ssnPlaceholder': 'YYMMDD-XXXX',
    'sex': 'Gender',
    'boy': 'Boy',
    'girl': 'Girl',
    'startYear': 'Start year',
    'grade': 'Requested grade at school start',
    'language': 'First language',
    'present': 'Current school/pre-school',
    'afternoonSchool': 'Request placement at Afternoon School',
    'comment': 'Additional comments',
    'gdpr': 'I approve the use of above personal data in accordance with',
    'submit': 'Submit application',
    'successMessage': 'Thank you for the application.',
    'successClose': 'Close',
    'failDuplicateMessage': 'Application with this social security number already exists.',
    'failErrorMessage': 'Something went wrong, contact administrator.',
    'failClose': 'Back',
    'yes': 'Yes',
    'no': 'No',
    'address': 'Address',
    'phoneNumber': 'Mobile number',
    'email': 'Email'
  }
}

export class AnsokningForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      defaultGrade: null,
      gradeList: [],
      inputData: {
        'Gender': 'Boy',
        'AfternoonSchool':true,
        'StartYear': year[0],
        'Grade': '',
        'language': 'svenska (svenska)'
      },
      submitSuccess:false,
      submitFailed:false,
      errorMessage:siteLang['Svenska'].failErrorMessage,
      linkedData: {},
      currentLanguage: 'Svenska',
      submitDisable: false
    }

    this.populateData = this.populateData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.submitData = this.submitData.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.onFormLanguageSwitch = this.onFormLanguageSwitch.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  onModalClose(){
    //this.setState({submitSuccess:false});
    window.location.reload();
  }

  onFormLanguageSwitch(event){
    let newLanguage = event.target.innerText;
    let {currentLanguage} = this.state;

    if(currentLanguage === newLanguage){
      return;
    }

    //console.log(newLanguage);
    this.setState({currentLanguage:newLanguage});
  }

  render() {
    let { inputData, submitSuccess, submitFailed, currentLanguage, errorMessage } = this.state;
    let aLang = siteLang[currentLanguage];

    return (
      <React.Fragment>
        <Dialog
          open={submitSuccess}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Ansökan skickad"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {aLang.successMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onModalClose} color="primary">
              {aLang.successClose}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={submitFailed}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          onClose={() => this.setState({submitFailed:false})}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"FEL"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({submitFailed:false})} color="primary">
              {aLang.failClose}
            </Button>
          </DialogActions>
        </Dialog>
        <form className="needs-validation" style={{ maxWidth: '1000px', marginLeft: '10px' }} noValidate onSubmit={this.onSubmit}>
          <div className="row" style={{background:"#5f2c0d none repeat center center",color:"#FFF"}}>
            <div className="col">
              <h2>{aLang.pageTitle}</h2>
            </div>
            <div className="col-md-2" style={{display:'flex', alignItems:'center'}}>
              <div>
                {
                  Object.entries(siteLang).map((item, index) => {
                    let [key] = item;
                    return (currentLanguage === key)
                      ? <b key={index}><span style={{marginLeft:'0.5rem', color:'#FFF', cursor:'pointer'}} onClick={this.onFormLanguageSwitch}>{key}</span></b>
                      : <span style={{marginLeft:'0.5rem', color:'#FFF', cursor:'pointer'}} key={index} onClick={this.onFormLanguageSwitch}>{key}</span>
                  })
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="form-row">
            <div className="form-group col-md-6">
              <h5>{aLang.headerStudent}</h5>
            </div>
            <div className="form-group col-md-6">
              {/*<img src="https://media1.britishschools.se/2015/12/British_schools-1.png"></img>*/}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstname">{aLang.firstName}</label>
              <input className="form-control" size="small" variant="outlined" id="FirstName" onChange={this.onChange} placeholder={aLang.firstName} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastname">{aLang.lastName}</label>
              <input className="form-control" size="small" variant="outlined" id="LastName" onChange={this.onChange} placeholder={aLang.lastName} required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="personnummer">{aLang.ssn}</label>
              <input className="form-control" size="small" pattern="[0-9]{6}-.{4}" variant="outlined" id="Ssn" onChange={this.onChange} placeholder={aLang.ssnPlaceholder} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="Sex">{aLang.sex}</label>
              <select className="custom-select" style={{width:"100%"}} value={inputData.Gender} name="Gender" id="Gender" onChange={this.onSelectChange} required>
                <option value="Boy">{aLang.boy}</option>
                <option value="Girl">{aLang.girl}</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="yearstart">{aLang.startYear}</label>
              <select className="custom-select" style={{width:"100%"}} value={inputData.StartYear} name="StartYear" id="StartYear" onChange={this.onSelectChange} required>
                {year.map((val, index) => {
                  return <option key={index} value={val}>{val}</option>
                })}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="arskurs">{aLang.grade}</label>
              <select className="custom-select" style={{width:"100%"}} value={inputData.Grade} name="Grade" id="Grade" onChange={this.onSelectChange} required>
                {this.state.gradeList.map((item, index) => {
                  return <option key={index} value={item.id}>{item.value}</option>
                })}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="nativelang">{aLang.language}</label>
              <CountrySelect onChange={this.onLanguageChange.bind(this)} value={inputData.language} style={{padding:"6.3px 9px"}} width='100%' variant="outlined" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="nuvarandeskola">{aLang.present}</label>
              <input className="form-control" size="small" variant="outlined" id="Present" onChange={this.onChange} placeholder={aLang.present}/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="afternoonschool">{aLang.afternoonSchool}</label>
              <select className="custom-select" style={{width:"100%"}} value={inputData.AfternoonSchool} name="AfternoonSchool" id="AfternoonSchool" onChange={this.onSelectChange} required>
                <option value={true}>{aLang.yes}</option>
                <option value={false}>{aLang.no}</option>
              </select>
            </div>
          </div>
          <hr />
          <SiblingList onChange={this.onDataChange} language={aLang} dataId='sibling' />
          <ParentList onChange={this.onDataChange} language={aLang} dataId='parent' />
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="comment">{aLang.comment}</label>
              <textarea id="Comment" maxLength={500} className="form-control" size="small" variant="outlined" onChange={this.onChange} rows={3}></textarea>
            </div>
          </div>
          <hr />

          <div className="form-row">
            <div className="form-group col-md-12">
              <input className="checkbox" type="checkbox" id="gdpr" required />
              <label htmlFor="gdpr">{aLang.gdpr} <a href="https://www.datainspektionen.se/lagar--regler/dataskyddsforordningen/" target="_blank" rel="noopener noreferrer">GDPR</a></label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <button type="submit" onClick={this.onSubmit.bind(this)}>{aLang.submit}</button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if(!this.isValidForm()){
      console.log("invalid data...");
      return;
    }

    this.setState({
      submitDisable: true
    });

    this.submitData();
    return;
  }

  isValidForm() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var result = true;
    Array.prototype.filter.call(forms, function(form) {
      form.classList.add('was-validated');
      result = form.checkValidity();
    });

    return result;
  }

  async submitData(){
    let { inputData, linkedData } = this.state;
    inputData.Created = new Date();
    inputData.Modified = new Date();
    if(inputData.Gender === undefined || inputData.Gender === null){
      inputData.Gender = parseSSN(inputData.Ssn);
    }

    inputData.parent = linkedData['parent'];
    console.log(inputData);
    const response = await addApplicant(inputData);
    console.log(response);
    if(response.status === 409){
      // Duplicate error
      this.setState({
        submitFailed:true,
        errorMessage: siteLang[this.state.currentLanguage].failDuplicateMessage,
        submitDisable: false
      });

      return;
    } else if (response.status == 400) {
      this.setState({
          submitFailed: true,
          errorMessage: siteLang[this.state.currentLanguage].failErrorMessage,
          submitDisable: false
      });
      return;
    }
    const responseData = await response.json();
    const applicantId = responseData.id;
    const parents = linkedData['parent'];
    const siblings = linkedData['sibling'];

    if(parents !== undefined && parents !== null){
      await parents.map(async (parent) => {
        parent.ApplicantId = applicantId;
        await addParent(parent);
      }, Promise.resolve());
    }

    if(siblings !== undefined && siblings !== null){
      await siblings.map(async (sibling) => {
        sibling.ApplicantId = applicantId;
        await addSibling(sibling);
      }, Promise.resolve());
    }

    this.setState({submitSuccess:true, submitDisable: false});
  }

  onSelectChange(event, child){
    let {inputData} = this.state;
    inputData[event.target.name] = event.target.value;
    this.setState({inputData:inputData});
  }

  onLanguageChange(value){
    let {inputData} = this.state;
    inputData['language'] = value;
    this.setState({ inputData:inputData });
  }

  onDataChange(data, id){
    let {linkedData} = this.state;
    linkedData[id] = data;
    this.setState({ linkedData:linkedData });
  }

  onChange(event, key) {
    let target = event.target;
    let targetType = target.type;
    let value = target.value;
    let {inputData} = this.state;

    switch (targetType) {
      case 'text':
      case 'email':
      case 'textarea':
      case 'select-one':
        inputData[target.id] = value;
        break;
      default:
        console.log(targetType + " " + key);
        break;
    }

    this.setState({ inputData:inputData });
  }

  async populateData() {
    this.setState({ isLoading: true });
    const {inputData} = this.state;

    const status = (await (await getSettingById(2)).json()).filter((item) => item.enabled === true);
    const grades = (await (await getSettingById(1)).json()).filter((item) => item.enabled === true);
    const defaultGrade = grades.find((item) => item.default === true).value;
    const defaultStatus = status.find((item) => item.default === true).id;

    inputData.Grade = defaultGrade;
    inputData.Status = defaultStatus;

    console.log(grades);

    this.setState({ isLoading: false, gradeList: grades, defaultGrade: defaultGrade, inputData:inputData });
  }
}

export default withStyles(styles)(AnsokningForm);