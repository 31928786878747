import React, { Component } from 'react';
import {addEmailFormat, updateEmailFormat, deleteEmailFormat} from '../../API/email';
import MaterialTable from "material-table";
import {EmailFormatEditor} from './EmailFormatEditor';
import {Collapse,IconButton} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

export class EmailFormatList extends Component {
    static displayName = EmailFormatList.name;

    constructor(props) {
      super(props);
      this.state = {
        data:null,
        dialog: {open:false, text:''}
      };

      this.onDialogClose = this.onDialogClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
      let {data} = prevState;

      if(this.props.emailFormat !== undefined && data !== this.props.emailFormat){
        this.setState({data:this.props.emailFormat});
      }
    }

    renderTable(data) {
      let {dialog} = this.state;

      return (
        <div>
          <Collapse in={dialog.open} className="p-2">
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.onDialogClose}
                  >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {dialog.text}
            </Alert>
          </Collapse>
          <MaterialTable 
            columns={[
              { title: "Id", field: "id", type:"numeric", editable:"never", hidden:true, cellStyle:{width:"50px"} },
              { title: "Mallnamn", field: "name" },
              { title: "Ämne", field: "subject" }
            ]}
            data={data}
            detailPanel={rowData => <div className="p-3"><EmailFormatEditor value={rowData.format} onChange={this.onFormatChange.bind(this, rowData)} /></div>}
            options={{
              addRowPosition: 'first',
              draggable: false,
              filtering: false,
              selection: false,
              search: false,
              paging: false
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Inga fördefinierade mallar finns',
                addTooltip: 'Lägg till årskurs',
                deleteTooltip: 'Ta bort',
                editTooltip: 'Ändra',
                editRow: {
                  deleteText: 'Vill ni verkligen ta bort den här årskursen?',
                  cancelTooltip: 'Avbryt',
                  saveTooltip: 'Spara'
                }
              },
              header: {
                actions: ''
              }
            }}
            editable={{
              onRowAdd:this.onRowAdd.bind(this), 
              onRowUpdate:this.onRowUpdate.bind(this), 
              onRowDelete:this.onRowDelete.bind(this)
            }}
            title="Emailmallar" />
        </div>
      );
    }

    render() {
      let {data} = this.state;
      let content = data !== null
        ? this.renderTable(data)
        : "";

      return (
        <div>
          {content}
        </div>
      );
    }

    onDialogClose(){
      let {dialog} = this.state;
      dialog.open = false;
      this.setState({dialog:dialog});
    }

    async onFormatChange(rowData, value){
      const data = this.state.data;
      rowData.format = value;
      await updateEmailFormat(rowData.id, rowData);
      this.props.onChange(data, false);
    }

    onRowAdd(newData, event){
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            if(!newData.hasOwnProperty('name') || newData.name === ''){
              let {dialog} = this.state;
              dialog.open = true;
              dialog.text = 'Kan inte lägga till rad utan namn.';
              this.setState({dialog:dialog});
              reject();
              return;
            } 

            const data = this.state.data;
            newData.format = '';
            newData = await this.apiAddNew(newData);
            data.push(newData);
            this.setState({ data });
            this.props.onChange(data, true);
          }
          resolve();
        }, 1000)
      })
    }
      
    onRowUpdate(newData, oldData) {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            const data = this.state.data;
            const index = data.indexOf(oldData);
            await updateEmailFormat(oldData.id, newData);
            data[index] = newData;
            this.setState({ data }, () => resolve());
            this.props.onChange(data, true);
          }
          resolve()
        }, 1000)
      })
    }
      
    onRowDelete(oldData){
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            let data = this.state.data;
            const index = data.indexOf(oldData);
            try {
              await deleteEmailFormat(data[index].id);
            } catch {
              console.log("foreign key restraint error");
              let {dialog} = this.state;
              dialog.open = true;
              dialog.text = 'Kan inte ta bort emailformat.';
              this.setState({dialog:dialog});
              reject();
              return;
            }
            data.splice(index, 1);
            this.setState({ data }, () => resolve());
            this.props.onChange(data, true);
          }
          resolve()
        }, 1000)
      })
    }

    async apiAddNew(data){
      const response = await addEmailFormat(data);

      if(!response.ok){
        throw new Error("invalid setting post");
      }

      return await response.json();
    }
}
